<template src="./index.html">
</template>

<script>
import {
  mapState,
  mapActions
} from 'vuex'
import TypeListBox from '_c/TypeListBox'
import { getUrlParma } from '../../util/jumpPage'

export default {
  async asyncData ({ store, request }) {
		const PAGE = store.state.commonData.page
    const DISPATCH = store.dispatch
		const QUERY = store.state.route.query
		const CURRENTPAGE = store.state.route.name

		DISPATCH('commonData/setCurrentType', +QUERY.type || 0)

    return await Promise.all([
      DISPATCH('commonData/setCurrentPage', CURRENTPAGE),
      DISPATCH('dataNews/getNewsList',{
				type:+QUERY.type || 0,
				request,
			})
    ])
  },
	computed: {
    ...mapState('commonData', {
      typeList: s => s.newsTypeList,
			type : s => s.typeList.currentType
		}),
    ...mapState('dataNews', {
      newsList: s => s.list,
			totalNum: s => s.total
		}),
  },
  components:{
		TypeListBox
  },
	methods:{
		getRealCreateTime(createTime){
			if(!createTime) return ''

			if(createTime.indexOf(' ') > -1){
					return createTime.substr(0, createTime.indexOf(' '))
			}else{
					return createTime
			}
		}
	}
}
</script>
