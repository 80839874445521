<template>
	<div class="section-wrap">
    <div class="grid-section">
      <div class="grid-col gc-w1or1">
        <div class="filter-menu-container mt-30 m-hide-grid">
          <div class="filter-list-container">
            <div class="filter-list-item">
              <div class="filter-title">应用场景：</div>
              <div class="filter-list">
                <ul>
                  <li class="list-item" v-for="(item, index) in typeList" :key="index">
                    <a href="javascript:void(0)" :class="['item-link', {'actived':index == type}]"  @click.prevent="change_type(index)">{{item}}</a>
									</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  mapState,
  mapActions
} from 'vuex'
import { jump,getUrlParma } from '../util/jumpPage'

export default {
  name:'typelistbox',
	props:{
		typeList:{
			type: Array,
			default: []
		}
	},
  data(){
    return {
    }
  },
  computed: {
    ...mapState('commonData', {
      type: s => s.typeList.currentType
    })
  },
  methods: {
		change_type(index){
			let currentPage = this.$router.history.current.name
			let pageParmas = {
				type : index
			}

			getUrlParma('search') && (pageParmas.search = getUrlParma('search'))

			jump(this,{
				page:currentPage,
				deep:true,
				pageParmas
			})
		}
  }
}
</script>
